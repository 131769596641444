.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
  z-index: 1;
}

.elevation-profile {
  height: 30vh;
}

.ride-info {
  height: 20vh;
}

.ride-info ul {
  list-style-type: none;
}